<template>
  <PagePreloader :has-access="hasAccess"/>
  <AuthMiddleware @update-access="hasAccess = $event"/>
  <router-view/>
</template>

<script>
import PagePreloader from "@/pages/PagePreloader.vue"
import AuthMiddleware from "@/pages/AuthMiddleware.vue"
import { scrollTop, updateScrollTop } from "@/shared/utils/scrollTop"

export default {
  components: {
    PagePreloader,
    AuthMiddleware,
  },
  data: () => ({
    hasAccess: localStorage.getItem('otp_code') == '1979'
  }),
  setup() {
    return {
      scrollTop,
      updateScrollTop,
    }
  },
  mounted() {
    document.querySelector('#app').addEventListener('scroll', this.updateScrollTop)
  },
  beforeUnmount() {
    document.querySelector('#app').removeEventListener('scroll', this.updateScrollTop)
  }
}

</script>

<style lang="scss">

#app {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

</style>
