<template>
    <div
        :class="{
            [this.desktopClass]: true,
            // 'only-desktop': true,
        }"
        class="flex justify-center gap-[2.1296296296296298vw] pt-[6.509971509971509vw] pb-[2.4074074074074074vw]"
    ><!-- bg-[#10161c]   #18232D -->
        <v-img name="cabsat/broadcast-pro-logo.png" class="w-[14.814vw] h-[3.70vw]"/>
        <v-img name="cabsat/satellite-pro-logo.png" class="w-[14.814vw] h-[3.70vw]"/>
    </div>
</template>

<script>

export default {
    props: {
        class: {
            type: String,
            default: ''
        },
        desktopClass: {
            type: String,
            default: ''
        },
    },
    name: 'v-navigation'
}

</script>

<style lang="scss" scoped>

.only {
    &-mobile {
        @media (min-width: 769px) {
            display: none;
        }
    }
    &-desktop {
        @media (max-width: 768px) {
            display: none;
        }
    }
}

</style>

<style lang="scss">

@media (min-width: 768px) {
    .max-screen-md {
        .gap-\[2\.1296296296296298vw\] {
            gap: 16.35851851851852px;
        }
        .pt-\[6\.509971509971509vw\] {
            padding-top: 49.99849145299145px;
        }
        .pb-\[2\.4074074074074074vw\] {
            padding-bottom: 18.494814814814816px;
        }
        .w-\[14\.814vw\] {
            width: 113.8944px;
        }
        .h-\[3\.70vw\] {
            height: 28.416px;
        }
    }
}

</style>
