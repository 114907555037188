import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: {
      name: 'stream'
    }
  },
  {
    path: '/next-lector',
    children: [
      {
        path: ':id',
        name: 'next-lector',
        component: () => import('@/pages/NextLector.vue'),
      },
      {
        path: ':id/stand',
        name: 'next-lector-stand',
        component: () => import('@/pages/stand/NextLectorStand.vue'),
      },
    ],
  },
  {
    path: '/stream',
    name: 'stream',
    component: () => import('@/pages/StreamView.vue')
  },
  {
    path: '/stream/stand',
    name: 'stream-stand',
    component: () => import('@/pages/stand/StreamStandView.vue')
  },
  {
    path: '/lector',
    name: 'lectors',
    children: [
      {
        path: ':id',
        name: 'lector',
        component: () => import('@/pages/LectorSummary.vue'),
      },
      {
        path: ':id/stand',
        name: 'lector-stand',
        component: () => import('@/pages/stand/LectorSummaryStand.vue'),
      },
    ],
  },
  {
    path: '/final-summary/:id',
    name: 'final-summary',
    component: () => import('@/pages/FinalSummary.vue')
  },
  {
    path: '/final-summary-stand/:id',
    name: 'final-summary-stand',
    component: () => import('@/pages/stand/FinalSummaryStand.vue')
  },
  // {
  //   path: '/schedule',
  //   name: 'schedule',
  //   component: () => import('@/pages/ScheduleView.vue')
  // },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
