<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        class="animation-analizing"
    >
        <rect
            v-for="(item, index) in animationAnalizing"
            :key="index"
            v-bind="item"
            fill="currentColor"
        />
    </svg>
</template>

<script setup>

import { reactive, ref, onMounted, onBeforeUnmount, defineOptions } from "vue"

defineOptions({
    name: 'gpt-loading'
})

const startRects = [
    { x: "11", width: "2", height: "24", rx: "1" },
    { y: "7", width: "2", height: "10", rx: "1" },
    { x: "5", y: "4", width: "3", height: "16", rx: "1.5" },
    { x: "22", y: "7", width: "2", height: "10", rx: "1" },
    { x: "16", y: "4", width: "3", height: "16", rx: "1.5" }
];
const animationAnalizing = reactive(ref([...startRects]))
const animationAnalizingBool = ref(false)

let interval;

function updateLoadingIcon() {
    animationAnalizingBool.value = !animationAnalizingBool.value
    animationAnalizing.value = animationAnalizingBool.value
        ? startRects
        : [
            { x: "10", y: "10", width: "4", height: "4", rx: "2" },
            { y: "2", width: "2", height: "20", rx: "1" },
            { x: "5", y: "7", width: "2", height: "10", rx: "1" },
            { x: "22", y: "2", width: "2", height: "20", rx: "1" },
            { x: "17", y: "7", width: "2", height: "10", rx: "1" }
        ];
}

onMounted(() => {
    updateLoadingIcon()
    interval = setInterval(updateLoadingIcon, 600)
});

onBeforeUnmount(() => {
    clearInterval(interval)
});

</script>

<style lang="scss" scoped>

.animation-analizing {
    rect {
        transition: all .6s ease-in-out;
    }
}

</style>