<template>
    <div
        class="fixed top-0 bottom-0 left-0 right-0 bg-[#051E49] z-50 justify-center items-center transition-opacity duration-500 max-md:flex hidden"
        :class="{
            'opacity-0 pointer-events-none': progress >= 100
        }"
        v-if="hasAccess"
    >
        <div
            class="flex flex-col justify-center items-center gap-[3.076923076923077vw]"
        >
            <v-img
                name="cabsat/cabsat-logo.png"
                class="max-w-[44.1025641025641vw] max-h-[11.794871794871794vw]"
            />
            <div class="h-[0.1851851851851852vw] w-[44.35897435897436vw] bg-white rounded-sm relative">
                <div class="absolute top-0 bottom-0 left-0 max-w-full bg-[#e4a040]" :style="{
                    width: `${progress || 0}%`
                }"></div>
            </div>
            <h6 class="text-center flex-1 font-normal uppercase w-[52.307692307692314vw] leading-[4vw] text-[3.076923076923077vw]">Pioneering the Future of Media and Entertainment in MEASA</h6>
        </div>
    </div>
    <div
        class="fixed top-0 bottom-0 left-0 right-0 bg-[#051E49] z-30 flex justify-center items-center transition-opacity duration-500 max-md:hidden"
    >
        <div class="flex flex-col items-center">
            <div class="flex justify-center items-center gap-5">
                <v-img
                    name="cabsat/cabsat-logo.png"
                    class="max-w-[178.91px] max-h-[48.17px]"
                />
                <div class="w-0.5 h-[40px] bg-white rounded-sm relative"></div>
                <h6 class="flex-1 font-normal uppercase max-w-[350.93px] leading-[26.84px] text-[20.64px]">Pioneering the Future of Media and Entertainment in MEASA</h6>
            </div>
            <h5 class="mt-[9.25925925925926vh] mb-[3.7037037037037033vh] text-center flex-1 font-normal uppercase max-w-[356px] leading-[36px] text-[26px] tracking-[0.04em]">Scan with your mobile device for the best experience</h5>
            <v-img name="cabsat/qr.jpg" class="rounded-xl overflow-hidden"/>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        hasAccess: {
            type: Boolean,
            default: false
        },
    },
    data: () => ({
        progress: 0,
        interval: null
    }),
    watch: {
        hasAccess(newValue) {
            if (newValue) {
                this.interval = setInterval(() => {
                    this.progress++;
                    if (this.progress >= 100) clearInterval(this.interval);
                }, 3000 / 100);
            }
        },
    },
    mounted() {
        if (this.hasAccess) {
            this.interval = setInterval(() => {
                this.progress++;
                if (this.progress >= 100) clearInterval(this.interval);
            }, 3000 / 100);
        }
    },
    beforeUnmount() {
        if (this.interval) clearInterval(this.interval);
    },
}

</script>

<style lang="scss">
@media (min-width: 769px) {
    html {
        overflow: hidden;
    }
}
@media (min-width: 1080px) {
    .mt-\[9\.25925925925926vh\] {
        margin-top: 100px !important;
    }
    .mb-\[3\.7037037037037033vh\] {
        margin-bottom: 40px !important;
    }
}

// @media (min-width: 768px) {
//     .max-screen-md {
//         .max-w-\[44\.1025641025641vw\] {
//             max-width: 338.7076923076923px !important;
//         }
//         .max-h-\[11\.794871794871794vw\] {
//             max-width: 90.58461538461538px !important;
//         }
//         .w-\[44\.35897435897436vw\] {
//             width: 340.67692307692306px !important;
//         }
//         .w-\[52\.307692307692314vw\] {
//             width: 401.723076923077px !important;
//         }
//         .gap-\[3\.076923076923077vw\] {
//             gap: 23.58923076923077px;
//         }
//         .text-\[3\.076923076923077vw\] {
//             font-size: 23.58923076923077px;
//         }
//         .leading-\[4vw\] {
//             line-height: 30.72px !important;
//         }
//     }
// }

</style>
