<template>
    <div
        class="fixed top-0 bottom-0 left-0 right-0 bg-[#051E49] z-40 flex-col transition-opacity duration-500 max-md:flex hidden p-[8.205128205128204vw] pt-[12.307692307692308vw] html-scroll-hidden overflow-y-auto overflow-x-hidden"
        :class="{
            'opacity-0 pointer-events-none': hasAccess
        }"
        v-if="!hideLogin"
    >
        <div>
            <icon-svg name="logo-doclub" class="w-[27.17948717948718vw] h-[7.179487179487179vw]"/>
            <!-- <div
                class="inline-flex flex-col justify-center items-center gap-[2.307692307692308vw]"
            >
                <v-img
                    name="cabsat/cabsat-logo.png"
                    class="max-w-[44.1025641025641vw] max-h-[11.794871794871794vw]"
                />
                <div class="h-[0.1851851851851852vw] w-[44.35897435897436vw] bg-white rounded-sm relative">
                    <div class="absolute top-0 bottom-0 left-0 max-w-full bg-[#e4a040]" :style="{
                        width: `0%`
                    }"></div>
                </div>
                <h6 class="text-center flex-1 font-normal uppercase w-[52.307692307692314vw] leading-[4vw] text-[3.076923076923077vw]">Pioneering the Future of Media and Entertainment in MEASA</h6>
            </div> -->
        </div>
        <div class="flex flex-col mt-[24.615384615384617vw]">
            <!-- <h1 class="text-[10.256410256410255vw] leading-[11.282051282051283vw]"><h1 class="text-[#7646ff] text-[10.256410256410255vw] leading-[11.282051282051283vw]">AI-Powered</h1> Event assistant</h1> -->
            <h1 class="font-[Ubuntu] text-[10.256410256410255vw] leading-[11.282051282051283vw]">Doclub<br>AI-Assistant </h1>
            <!-- <p class="text-[#B9B9B9] text-[4.102564102564102vw] leading-[1.25] mt-[8.205128205128204vw]">To access the service, please enter the code that was sent in your cover letter.</p> -->
            <p class="text-[#B9B9B9] text-[4.102564102564102vw] leading-[1.25] mt-[6.153846153846154vw]">Enter your code</p>
            <div class="hidden">{{ JSON.stringify(OTP_code) }}</div>
            <div class="hidden">{{ OTP_code }}</div>
            <div class="flex justify-between items-center gap-[4.102564102564102vw] mt-[4.102564102564102vw] [&:has(input:focus)>input]:border">
                <input
                    autofocus
                    class="text-[4.102564102564102vw] h-[11.282051282051283vw] w-[12.82051282051282vw] flex-1 bg-[#132F60] border-[#7646FF] rounded-xl text-center outline-none appearance-none transition-all"
                    :class="{
                        'border !border-[#EA6361]': codeIsValid === false,
                        // bg-[#FFEBEB] text-[#C51311]
                    }"
                    v-for="(code, index) in OTP_code"
                    :key="index"
                    :value="code"
                    name="code"
                    type="number"
                    inputmode="numeric"
                    autocomplete="one-time-code"
                    @focus="allPreviousIsNull($event, index)"
                    @input="update_OTP_code($event, index)"
                    @keydown="handleInput($event, index)"
                    @keyup.enter="saveOTPCode"
                />
            </div>
            <button class="h-[11.282051282051283vw] rounded-xl mt-[7.179487179487179vw] bg-gradient-to-r from-[#01A4FF] to-[#793EDC] text-[4.102564102564102vw]" :disabled="!codeIsValid" @click="saveOTPCode">Open</button>
        </div>
        <div class="pt-5 mt-auto">
            <a href="mailto:partners@doclub.tech" target="_blank" class="inline-flex items-center gap-[3.076923076923077vw] text-[#968FA9] hover:underline cursor-pointer">
                <icon-svg name="social/mail" class="w-[3.5897435897435894vw] h-[3.076923076923077vw]"/>
                <p class="text-[4.102564102564102vw] leading-[1.25]">partners@doclub.tech</p>
            </a>
        </div>
    </div>
    <div
        class="fixed top-0 bottom-0 left-0 right-0 bg-[#051E49] z-40 transition-opacity duration-500 md:flex hidden html-scroll-hidden"
        :class="{
            'opacity-0 pointer-events-none': hasAccess
        }"
        v-if="!hideLogin"
    >
        <div class="flex flex-col pl-5 pr-3 pb-[32px] pt-[48px] w-full max-w-[390px] min-w-[370px] mx-auto overflow-y-auto overflow-x-hidden">
            <div>
                <icon-svg name="logo-doclub" class="w-[105.99999999999999px] h-[27.999999999999996px]"/>
                <!-- <div
                    class="inline-flex flex-col justify-center items-center gap-[9px]"
                >
                    <v-img
                        name="cabsat/cabsat-logo.png"
                        class="max-w-[172px] max-h-[45.99999999999999px]"
                    />
                    <div class="h-[0.7222222222222222px] w-[173px] bg-white rounded-sm relative">
                        <div class="absolute top-0 bottom-0 left-0 max-w-full bg-[#e4a040]" :style="{
                            width: `0%`
                        }"></div>
                    </div>
                    <h6 class="text-center flex-1 font-normal uppercase w-[204px] leading-[15.6px] text-[12px]">Pioneering the Future of Media and Entertainment in MEASA</h6>
                </div> -->
            </div>
            <div class="flex flex-col mt-[96px]">
                <!-- <h1 class="text-[40px] leading-[44px]"><h1 class="text-[#7646ff] text-[40px] leading-[44px]">AI-Powered</h1> Event assistant</h1> -->
                <h1 class="font-[Ubuntu] text-[40px] leading-[44px]">Doclub<br>AI-Assistant </h1>
                <!-- <p class="text-[#B9B9B9] text-[15.999999999999996px] leading-[1.25] mt-[31.999999999999993px]">To access the service, please enter the code that was sent in your cover letter.</p> -->
                <p class="text-[#B9B9B9] text-[15.999999999999996px] leading-[1.25] mt-[24px]">Enter your code</p>
                <div class="hidden">{{ JSON.stringify(OTP_code) }}</div>
                <div class="hidden">{{ OTP_code }}</div>
                <div class="flex justify-between items-center gap-[15.999999999999996px] mt-[15.999999999999996px] [&:has(input:focus)>input]:border">
                    <input
                        autofocus
                        class="text-[15.999999999999996px] h-[44px] w-[49.99999999999999px] flex-1 bg-[#132F60] border-[#7646FF] rounded-xl text-center outline-none appearance-none transition-all"
                        :class="{
                            'border !border-[#EA6361]': codeIsValid === false,
                            // bg-[#FFEBEB] text-[#C51311]
                        }"
                        v-for="(code, index) in OTP_code"
                        :key="index"
                        :value="code"
                        name="code"
                        type="number"
                        inputmode="numeric"
                        autocomplete="one-time-code"
                        @focus="allPreviousIsNull($event, index)"
                        @input="update_OTP_code($event, index)"
                        @keydown="handleInput($event, index)"
                        @keyup.enter="saveOTPCode"
                    />
                </div>
                <button class="h-[44px] rounded-xl mt-[27.999999999999996px] bg-gradient-to-r from-[#01A4FF] to-[#793EDC] text-[15.999999999999996px]" :disabled="!codeIsValid" @click="saveOTPCode">Open</button>
            </div>
            <div class="pt-5 mt-auto">
                <a href="mailto:partners@doclub.tech" target="_blank" class="inline-flex items-center gap-[12px] text-[#968FA9] hover:underline cursor-pointer">
                    <icon-svg name="social/mail" class="w-[13.999999999999998px] h-[12px]"/>
                    <p class="text-[15.999999999999996px] leading-[1.25]">partners@doclub.tech</p>
                </a>
            </div>
        </div>
        <v-img name="cabsat/auth-photo.jpeg" class="p-[30px] max-lg:p-5 max-w-[70%]" img-class="rounded-[20px] object-cover"/>
    </div>
</template>

<script setup>
import { defineEmits, ref, computed, nextTick } from "vue"

const emit = defineEmits(['update-access']);

const hideLogin = ref(false);

const OTP_code = ref([null, null, null, null]);
const hasAccess = ref(false);

setTimeout(() => {
    hasAccess.value = localStorage.getItem('otp_code') == '1979';
    if (hasAccess.value) {
        setTimeout(() => {
            hideLogin.value = true;
        }, 3000);
    }
}, 100);

const codeIsValid = computed(() => {
    const otp__code = OTP_code.value?.join?.('');
    if (otp__code?.length === 4) {
        return otp__code == '1979'
    }
    return null
})

function saveOTPCode() {
    if (!codeIsValid.value) return;
    localStorage.setItem('otp_code', OTP_code.value?.join?.(''));
    hasAccess.value = true;
    emit('update-access', true)
    setTimeout(() => {
        hideLogin.value = true;
    }, 3000);
}

function handleInput (e, index) {
    if (e.key === 'Backspace' || e.key === 'Delete') {
        if (e.target.previousElementSibling && e.target.previousElementSibling?.value != '' && e.target?.value == '') {
			OTP_code.value[Math.max(0, index - 1)] = '';

			e.target.previousElementSibling.focus()
			return
		}

        if (e.target.value != '') return
        if (e.target.previousElementSibling?.value == '') {
			e.target.previousElementSibling.focus()
		}
		else {
			e.target.focus()
		}

        // if (e.target.previousElementSibling) e.target.previousElementSibling.focus()
    }
}

function update_OTP_code (event, index) {
    const { target } = event;
    // if (props.loading) return;
    let { value } = target;
    console.log('update_OTP_code', value, event.ctrlKey, event)
    console.log('update_OTP_code', index, OTP_code.value)
    if (value.replace(/\D/g, '') == '') {
        OTP_code.value[index] = null

		if (target.previousElementSibling?.value == '') {
			target.previousElementSibling.focus()
		}
		else {
			target.focus()
		}

        // if (target.previousElementSibling) target.previousElementSibling.focus()

        nextTick(() => {
            OTP_code.value[index] = '';
        })
        return
    }
    const temp_value = value.replace(/\D/g, '');
    if (temp_value.length == 4) {
        temp_value.split('').forEach((val, i) => {
            console.log('new value', val);
            OTP_code.value[i] = val;
        });
        return;
    }
    value = parseInt(temp_value)
    const newValue = value > 9 ? parseInt(('' + value).at(-1)) : value
    OTP_code.value[index] = null;
    nextTick(() => {
        console.log('new value', newValue);
        OTP_code.value[index] = newValue;
        if (target.nextElementSibling) target.nextElementSibling.focus()
    })

}

function allPreviousIsNull (event, index) {
    const isNull = OTP_code.value.slice(0, index).some(num => num == null)

    if (isNull) {
		if (event.target.previousElementSibling?.value == '') {
			event.target.previousElementSibling.focus()
		}
		else {
			event.target.focus()
		}
	}

    // if (isNull && event.target.previousElementSibling) event.target.previousElementSibling.focus()
}

</script>

<style lang="scss">
input:nth-child(4) ~ input {
    display: none;
}
html:has(.html-scroll-hidden:not(.pointer-events-none)) {
    overflow: hidden;
}
@media (min-width: 769px) {
    html {
        overflow: hidden;
    }
}
@media (min-width: 1080px) {
    .mt-\[9\.25925925925926vh\] {
        margin-top: 100px !important;
    }
    .mb-\[3\.7037037037037033vh\] {
        margin-bottom: 40px !important;
    }
}

// @media (min-width: 768px) {
//     .max-screen-md {
//         .max-w-\[44\.1025641025641vw\] {
//             max-width: 338.7076923076923px !important;
//         }
//         .max-h-\[11\.794871794871794vw\] {
//             max-width: 90.58461538461538px !important;
//         }
//         .w-\[44\.35897435897436vw\] {
//             width: 340.67692307692306px !important;
//         }
//         .w-\[52\.307692307692314vw\] {
//             width: 401.723076923077px !important;
//         }
//         .gap-\[3\.076923076923077vw\] {
//             gap: 23.58923076923077px;
//         }
//         .text-\[3\.076923076923077vw\] {
//             font-size: 23.58923076923077px;
//         }
//         .leading-\[4vw\] {
//             line-height: 30.72px !important;
//         }
//     }
// }

</style>
